@keyframes swal2-animate-success-line-tip
  0%
    top: 1.1875em
    left: .0625em
    width: 0

  54%
    top: 1.0625em
    left: .125em
    width: 0

  70%
    top: 2.1875em
    left: -.375em
    width: 3.125em

  84%
    top: 3em
    left: 1.3125em
    width: 1.0625em

  100%
    top: 2.8125em
    left: .8125em
    width: 1.5625em

@keyframes swal2-animate-success-line-long
  0%
    top: 3.375em
    right: 2.875em
    width: 0

  65%
    top: 3.375em
    right: 2.875em
    width: 0

  84%
    top: 2.1875em
    right: 0
    width: 3.4375em

  100%
    top: 2.375em
    right: .5em
    width: 2.9375em

@keyframes swal2-rotate-success-circular-line
  0%
    transform: rotate(-45deg)

  5%
    transform: rotate(-45deg)

  12%
    transform: rotate(-405deg)

  100%
    transform: rotate(-405deg)

.swal2-icon
  position: relative
  box-sizing: content-box
  justify-content: center
  width: 5em
  height: 5em
  border: .25em solid transparent
  border-radius: 50%
  border-color: #000
  font-family: inherit
  line-height: 5em
  cursor: default
  user-select: none
  -webkit-tap-highlight-color: transparent

  &.success
    border-color: #a5dc86
    color: #a5dc86

    .swal2-success-circular-line-left
      position: absolute
      width: 3.75em
      height: 7.5em
      transform: rotate(45deg)
      border-radius: 50%
      top: -.4375em
      left: -2.0635em
      transform: rotate(-45deg)
      transform-origin: 3.75em 3.75em
      border-radius: 7.5em 0 0 7.5em
      @apply bg-white

    .swal2-success-line-tip
      display: block
      position: absolute
      z-index: 2
      height: .3125em
      border-radius: .125em
      background-color: #a5dc86
      top: 2.875em
      left: .8125em
      width: 1.5625em
      transform: rotate(45deg)
      animation: swal2-animate-success-line-tip .75s

    .swal2-success-line-long
      display: block
      position: absolute
      z-index: 2
      height: .3125em
      border-radius: .125em
      background-color: #a5dc86
      top: 2.375em
      right: .5em
      width: 2.9375em
      transform: rotate(-45deg)
      animation: swal2-animate-success-line-long .75s

    .swal2-success-ring
      position: absolute
      z-index: 2
      top: -.25em
      left: -.25em
      box-sizing: content-box
      width: 100%
      height: 100%
      border: .25em solid rgba(165,220,134,.3)
      border-radius: 50%

    .swal2-success-fix
      position: absolute
      z-index: 1
      top: .5em
      left: 1.625em
      width: .4375em
      height: 5.625em
      transform: rotate(-45deg)
      @apply bg-white

    .swal2-success-circular-line-right
      position: absolute
      animtion: swal2-rotate-success-circular-line 4.25s ease-in
      width: 3.75em
      height: 7.5em
      top: -.6875em
      left: 1.875em
      transform: rotate(-45deg)
      transform-origin: 0 3.75em
      border-radius: 0 7.5em 7.5em 0
      @apply bg-white
