@font-face
  font-family: 'isansx'
  font-style: normal
  font-weight: 300
  src: url('/assets/fonts/IRANSansXFaNum-Light.woff') format('woff'), url('/assets/fonts/IRANSansXFaNum-Light.woff2') format('woff2')

@font-face
  font-family: 'isansx'
  font-style: normal
  font-weight: 400
  src: url('/assets/fonts/IRANSansXFaNum-Regular.woff') format('woff'), url('/assets/fonts/IRANSansXFaNum-Regular.woff2') format('woff2')

@font-face
  font-family: 'isansx'
  font-style: normal
  font-weight: 500
  src: url('/assets/fonts/IRANSansXFaNum-Medium.woff') format('woff'), url('/assets/fonts/IRANSansXFaNum-Medium.woff2') format('woff2')

@font-face
  font-family: 'isansx'
  font-style: normal
  font-weight: 700
  src: url('/assets/fonts/IRANSansXFaNum-Bold.woff') format('woff'), url('/assets/fonts/IRANSansXFaNum-Bold.woff') format('woff2')

@font-face
  font-family: 'isansx'
  font-style: normal
  font-weight: 900
  src: url('/assets/fonts/IRANSansXFaNum-Black.woff') format('woff'), url('/assets/fonts/IRANSansXFaNum-Black.woff') format('woff2')
