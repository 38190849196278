.post-content
  p
    font-size: inherit
    font-weight: inherit
    font-family: inherit
    line-height: inherit
    @apply mb-5

  h1,
  h2,
  h3,
  h4,
  h5
    @apply font-bold mb-5

  h1
    @apply text-xl

  h2
    @apply text-lg

  h3,
  h3,
  h5
    @apply text-base

  ul
    list-style: none
    @apply mb-5

    li
      position: relative
      @apply pr-6

      &:before
        content: ''
        position: absolute
        border-radius: 100%
        top: 8px
        right: 0
        width: 14px
        height: 14px
        background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxMyI+PHRpdGxlPmNoZWNrPC90aXRsZT48cGF0aCBmaWxsPSIjZmZmIiBkPSJNOSwxOGExLDEsMCwwLDEtLjcxLS4yOWwtNS01YTEsMSwwLDAsMSwxLjQxLTEuNDFMOSwxNS41OSwxOS4yOSw1LjI5YTEsMSwwLDAsMSwxLjQxLDEuNDFsLTExLDExQTEsMSwwLDAsMSw5LDE4WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMgLTUpIi8+PC9zdmc+")
        background-repeat: no-repeat
        @apply ml-1.5 bg-primary

  .aligncenter
    text-align: center

    img
      margin: 0 auto
