:root
  --font-size-base: 14px
  --family-system: ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  --family-isansx: isansx, roboto, ui-sans-serif, system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

  /** Map **/
  --marker-size: 49px
  --marker-outer-wave-size: 89px
  --marker-inner-wave-size: 69px
  --marker-inner-stroke: 10px

  /** Toastify **/
  --toastify-z-index: 999999999999999

  @media (min-width: theme('screens.2xl'))
    --font-size-base: 16px
