/* Background */
.loader-container
  @apply relative

  svg
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -100%)

  svg path
    fill: currentColor
    transform-origin: center
    animation: rotate 1.5s linear infinite

  .loaderreverse
    animation-direction: reverse !important

@keyframes rotate
  to
    transform: rotate(360deg)

  from
    transform: rotate(0deg)
