.dw-indicator-link
  &:before
    content: ''
    background: radial-gradient(circle at center, #ffd556, #ffc823)
    @apply transform-gpu
    @apply scale-90
    @apply opacity-0
    @apply absolute
    @apply inset-0
    @apply -z-1

  &.active
    @apply text-dark-900

    &:before
      @apply scale-100
      @apply opacity-100
