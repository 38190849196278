:focus-visible,
button:focus
  outline: none

html
  font-size: var(--font-size-base, 16px)
  -webkit-scroll-behavior: smooth
  scroll-behavior: smooth

body
  font-family: var(--family-isansx, var(--family-system, 'sans-serif'))
  text-align: right
  direction: rtl
  background-repeat: no-repeat
  background-size: cover
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  @apply min-h-screen
  @apply text-base
  @apply font-normal

svg.icon
  display: inline-block
  vertical-align: middle
  line-height: 1
  fill: currentColor
  @apply transition-all

  path
    transition: inherit

body.blurred
  .site-wrap
    filter: blur(10px)

button
  transition: all 250ms ease-in-out

  &[data-loading]
    opacity: .4

.ltr
  direction: ltr

.rtl
  direction: rtl

.requests-layout
  background-image: url(../../public/assets/images/cars-bg.png)
  background-position: bottom -40px center
  background-repeat: no-repeat

.faq-bg
  background-image: url(../../public/assets/images/faq-bg.png)
  background-position: center center
  background-size: cover
  height: 241px
  width: 671px
  background-repeat: no-repeat
  position: absolute
  top: 0
  left: 50%
  transform: translateX(-50%)

.about-orange-bg
  background-image: url(../../public/assets/images/orange-bg-min.webp)
  background-position: center center
  background-size: cover
  min-height: 213px
  background-repeat: no-repeat

.about-car-bg
  background-image: url(../../public/assets/images/car-dark-bg-min.webp)
  background-position: center center
  background-size: cover
  min-height: 285px
  background-repeat: no-repeat

.about-orange-bg2
  background-image: url(../../public/assets/images/orange-bg2-min.webp)
  background-position: center center
  background-size: cover
  min-height: 165px
  background-repeat: no-repeat

.request-layout-main
  width: 100%
  max-width: 466px

.font-isans
  font-family: var(--family-isansx, var(--family-system, 'sans-serif'))

.blockUI
  @apply absolute -inset-2 bg-white bg-opacity-70 z-50

.bill-table
  tr
    td,
    th
      @apply py-5
      @apply border-b

      &:last-child
        @apply text-left

a,
input,
select
  -webkit-tap-highlight-color: transparent

// Prevent ios zoom when focusing inputs
@media screen and (-webkit-min-device-pixel-ratio:0)
  select,
  textarea,
  input
    font-size: 16px !important

.alignfull
  width: 100vw
  left: 50%
  right: 50%
  margin-left: -50vw
  margin-right: -50vw
  position: relative
  max-width: 100vw
