.primary-field
  @apply relative flex

  .icn
    @apply w-9 text-center absolute top-1/2 transform-gpu -translate-y-1/2 right-4

    svg
      @apply text-neutral-350 w-8 h-8

  input[type=text],
  input[type=email],
  input[type=number],
  input[type=tel],
  input[type=url],
  input[type=password],
  .field,
  textarea
    @apply block w-full bg-neutral-100 h-16 pr-14 text-xl text-neutral-500 font-bold border-2 border-transparent transition-all rounded-lg

    &.normal-text
      @apply font-normal

    &.no-icon
      @apply px-5

    &:focus
      @apply border-primary border-opacity-70

  textarea
    @apply h-40 p-5 resize-y

  .field
    line-height: 4rem
    @apply text-opacity-60 text-neutral-500

    &:focus
      @apply text-opacity-100 text-neutral-500

.button
  @apply h-16 rounded-lg text-2xl font-black text-white bg-green-400 shadow-darkgreen

  &.left
    @apply rounded-r-none

  &.right
    @apply rounded-l-none

  &.cancel
    @apply bg-neutral-350 shadow-gray

  &.black
    @apply bg-dark-800 shadow-black

  &.small
    @apply text-lg h-14 font-bold

.primary-input
  @apply h-14 border border-gray-500 border-opacity-40 rounded-md px-4 transition-all

  &:hover
    @apply border-opacity-75

  &:focus
    @apply border-primary

  &.borderless
    @apply bg-dark-100 bg-opacity-60 border-transparent text-dark-900 border-2

    &:hover
      @apply border-gray-500 border-opacity-75

    &:focus
      @apply border-primary

  &.with-icon
    @apply pr-10

input.custom-checkbox
  position: absolute
  z-index: -100
  opacity: 0
  visibility: hidden
  display: none

  &:not(:checked)
    & + .box
      svg
        display: none
